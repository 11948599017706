import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { Formik, Form } from 'formik';
import emailjs from "emailjs-com";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

import MapIcon from '../../../assets/images/MapIcon.png'

import "./contactTemplate.scss";

const inputs = [
  [
    { name: "name", label: "Nombre", type: "text" },
    { name: "email", label: "Dirección de correo electrónico", type: "email" },
    { name: "company", label: "Nombre de compañía", type: "text" }
  ],
  [
    { name: "lastname", label: "Apellido", type: "text" },
    { name: "phone", label: "Teléfono", type: "text" },
  ]
];

const ContactTemplate = (props) => {
  const data = useStaticQuery(graphql`
    query QUERY_CONTACT {
      allContentfulPaginaContacto {
        nodes {
          buttonText
          description {
            raw
          }
          interestProducts
          pageTitle
          subjects
        }
      }
    }
  `);

  const info = data.allContentfulPaginaContacto.nodes[0];

  const {
    buttonText,
    description,
    interestProducts,
    pageTitle,
    subjects
  } = info;

  const initialValues = {
    name: "",
    lastname: "",
    interestProduct: "",
    phone: "",
    subject: "",
    company: "",
    comments: ""
  }

  const sendEmail = (data) => {
    emailjs.send("service_adtee3e", "template_964kghn", data, "user_bGG8uZHY1QhC8YtGooeEO")
      .then((res) => {
        if (res.status === 200) {
          alert("Mensaje enviado correctamente. Pronto nos pondremos en contacto contigo.");
          window.location.reload();
        }
      })
      .catch(() => {
        alert("Hubo un error en el envío del mensaje, por favor inténtalo más tarde.");
        window.location.reload();
      })
  }

  const desc = JSON.parse(description.raw).content[0].content[0].value;

  return (
    <div className="t-contact">
      <div className="t-contact__content">
        <h1 className="t-contact__content__title">{pageTitle}</h1>
        <p className="t-contact__content__desc">{desc}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            sendEmail(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form className="t-contact__content__form">
              <div className="t-contact__content__form__inputs">
                {inputs.map((column, i) => (
                  <div key={i} className="t-contact__content__form__column">
                    {column.map((input) => (
                      <div key={input.name} className="t-contact__content__form__field">
                        <label htmlFor={input.name}>
                          {input.label} <span>*</span>
                        </label>
                        <input
                          id={input.name}
                          name={input.name}
                          type={input.type}
                          value={values[input.name]}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    ))}
                  </div>
                ))}
                <div className="t-contact__content__form__column">
                  <div className="t-contact__content__form__field">
                    <label
                      htmlFor="interestProduct"
                      aria-label="Grupo de productos de interés"
                    >
                      Grupo de productos de interés <span>*</span>
                    </label>
                    <select
                      id="interestProduct"
                      name="interestProduct"
                      value={values.interestProduct}
                      onChange={handleChange}
                      required
                    >
                      <option
                        value=""
                        label="- Seleccionar -"
                        aria-label="- Seleccionar -"
                      />
                      {interestProducts.map(item => (
                        <option
                          key={item}
                          value={item}
                          label={item}
                          aria-label={item}
                        />
                      ))}
                    </select>
                  </div>
                  <div className="t-contact__content__form__field">
                    <label
                      htmlFor="subject"
                      aria-label="Seleccione un tema de la lista"
                    >
                      Seleccione un tema de la lista <span>*</span>
                    </label>
                    <select
                      id="subject"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      required
                    >
                      <option
                        value=""
                        label="- Seleccionar -"
                        aria-label="- Seleccionar -"
                      />
                      {subjects.map(item => (
                        <option
                          key={item}
                          value={item}
                          label={item}
                          aria-label={item}
                        />
                      ))}
                    </select>
                  </div>
                  <div className="t-contact__content__form__field">
                    <label
                      htmlFor="comments"
                      aria-label="Proporcione sus comentarios y/o preguntas a continuación"
                    >
                      Proporcione sus comentarios y/o preguntas a continuación
                    </label>
                    <textarea
                      id="comments"
                      name="comments"
                      value={values.comments}
                      onChange={handleChange}
                      rows={6}
                    />
                  </div>
                </div>
              </div>
              <button
                className="t-contact__content__form__submit"
                type="submit"
              >
                {buttonText}
              </button>
            </Form>
          )}
        </Formik>

      </div>
      <div className="t-contact__maps">
        <Map google={props.google} zoom={15}

          className={'t-contact__maps__container'}
          initialCenter={{
            lat: 3.4613858271885034,
            lng: -76.5140633033666
          }}

        >
          <Marker
            position={{ lat: 3.4613858271885034, lng: -76.5140633033666 }}
            icon={{ url: MapIcon }}
          />

        </Map>
      </div>
    </div>

  )
}

export default GoogleApiWrapper({
  apiKey: (process.env.GOOGLE_MAPS_API_KEY)
})(ContactTemplate);