import React from "react";
import Layout from "../components/organisms/layout/layout";
import ContactTemplate from "../components/templates/contact-template/contactTemplate";

const Contact = () => {
  return (
    <Layout title="Dicar | Contacto">
      <ContactTemplate />
    </Layout>
  )
}

export default Contact;